import { Card, Col, Container, Row } from "react-bootstrap";
import { OnlineNodeInfo } from "zkwasm-service-helper";

import { useGetOnlineNodesSummaryQuery } from "../../data/apiSlice";
import { Time } from "../../utils/inputs";

export default function OnlineNodesSummaryPage() {
  const { data: onlineNodesSummary } = useGetOnlineNodesSummaryQuery({});
  return (
    <Container style={{ minHeight: "58vh" }}>
      <Container className="mt-5"></Container>
      <Container className="mt-5">
        <Row className="task-detail detail-max-width">
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex ">Online Node Summary</Card.Header>
            </Card>
          </Col>
        </Row>
      </Container>
      {(
        [
          [onlineNodesSummary?.certified || [], "Certified"],
          [onlineNodesSummary?.active || [], "Active"],
          [onlineNodesSummary?.intern || [], "Intern"],
          [onlineNodesSummary?.inactive || [], "Inactive"],
        ] as [OnlineNodeInfo[], string][]
      ).map((perLevelIter) => (
        <table className="main-table mt-2" key={`${perLevelIter[1]}`}>
          <thead>
            <tr key={`${perLevelIter[1]}`}>
              <th
                style={{ width: "35%" }}
                scope="col"
              >{`${perLevelIter[1]} Addresses`}</th>
              <th scope="col" style={{ width: "25%" }}>
                Last Online
              </th>
              <th style={{ width: "25%" }}>Last Completed Dry Run Task Id</th>
            </tr>
          </thead>
          <tbody>
            {(perLevelIter[0] as OnlineNodeInfo[]).map((perNodeInfoIter) => {
              return (
                <tr key={perNodeInfoIter.address}>
                  <td
                    scope="row"
                    className="td-address"
                    style={{ padding: "8px 0" }}
                  >
                    <a href={`/node?address=${perNodeInfoIter.address}`}>
                      {perNodeInfoIter.address}
                    </a>
                  </td>
                  <td className="td-info">
                    <Time timestr={perNodeInfoIter.last_active_time}></Time>
                  </td>
                  <td className="td-info">
                    {perNodeInfoIter.last_completed_dry_run_task_id?.$oid ||
                      "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ))}
    </Container>
  );
}
