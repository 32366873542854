import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useGetTaskLogsQuery } from "../data/apiSlice";
import { signMessage } from "../utils/address";
import { selectL1Account } from "../data/accountSlice";
import { useAppSelector } from "../app/hooks";
import { LogQuery, ZkWasmUtil } from "zkwasm-service-helper";

interface TaskLogsModalProps {
  taskType: string;
  taskStatus: string;
}

const TaskLogsModal = ({ taskType, taskStatus }: TaskLogsModalProps) => {
  const account = useAppSelector(selectL1Account);
  const { taskId } = useParams<{ taskId: string }>();
  const [show, setShow] = useState(false);
  const [unknownErr, setUnknownErr] = useState(false);
  const [query, setQuery] = useState({
    id: taskId!,
    user_address: "",
  });
  const [signature, setSignature] = useState<string>("");
  const {
    data: logs,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTaskLogsQuery(
    {
      ...query,
      signature: signature,
    },
    {
      skip: !show || !signature,
    }
  ); // Fetch logs using RTK-Query

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    if (!account) {
      alert("Please connect Metamask!");
      return;
    }
    setShow(true);
    if (signature) {
      // dont need to rerun if fetched
      return;
    }
    let data: LogQuery = {
      ...query,
      user_address: account!.address.toLowerCase(),
    };
    let msg = ZkWasmUtil.createLogsMesssage(data);
    let sig = await signMessage(msg);
    setSignature(sig);
    setQuery({
      ...query,
      user_address: account!.address.toLowerCase(),
    });
  };

  return (
    <>
      <Button variant="primary" onClick={async () => {
        try {
          await handleShow()
        } catch (e) {
          setUnknownErr(true);
          console.error("Error", e);
        }
      }} className="logs-btn">
        View Logs
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Task Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSuccess && (
            <pre
              style={{
                overflow: "auto",
                maxHeight: "400px",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {logs}
            </pre>
          )}

          {isLoading && <Spinner></Spinner>}
          {isError && error !== undefined && (
            <div>{(error as Error).message}</div>
          )}
          {unknownErr && (
            <div>Internal error occurred, please refresh the page.</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskLogsModal;
