import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ByteDisplay, InputDisplay } from "../TaskDetail";

import { useEffect, useState } from "react";
import BN from "bn.js";
import { ZkWasmUtil } from "zkwasm-service-helper";
import { VerifyBatchProofModal } from "../../modals/verifyBatchProof";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";
import {
  useGetRound1InfosQuery,
  useGetRound2InfosQuery,
} from "../../data/apiSlice";

export const Round2BatchProofDetail = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const appConfig = useAppSelector(selectConfig);
  const [aggregate_proof, setAggregate_proof] = useState<BN[] | null>();
  const [shadow_instances, setShadowInstances] = useState<BN[] | null>();
  const [batchInstances, setBatchInstances] = useState<BN[] | null>();
  const [aux, setAux] = useState<BN[] | null>();
  const [targetInstances, setTargetInstances] = useState<BN[][] | null>();
  const {
    data: task,
    error,
    isLoading,
  } = useGetRound1InfosQuery({
    id: taskId,
    total: 1,
  });

  useEffect(() => {
    if (!task || task.data.length === 0) return;

    if (batchProofTask.proof) {
      setAggregate_proof(ZkWasmUtil.bytesToBN(batchProofTask.proof));
    }
    if (batchProofTask.shadow_instances) {
      setShadowInstances(ZkWasmUtil.bytesToBN(batchProofTask.shadow_instances));
    }

    if (batchProofTask.batch_instances) {
      setBatchInstances(ZkWasmUtil.bytesToBN(batchProofTask.batch_instances));
    }
    if (batchProofTask.aux) {
      setAux(ZkWasmUtil.bytesToBN(batchProofTask.aux));
    }
    if (batchProofTask.target_instances) {
      let input_instances = batchProofTask.target_instances.map((instance) => {
        return ZkWasmUtil.bytesToBN(instance);
      });

      setTargetInstances(input_instances);
    }
  }, [task]);

  // if task is undefined or len 0, return no task found

  if (!task || task.data.length === 0) {
    return <div>No task found</div>;
  }
  // if task is not undefined, get the task from task[0]
  const batchProofTask = task?.data[0];

  const network = batchProofTask.auto_submit_network_chain_id;
  const chainName =
    appConfig.chain_info_list.find((d: any) => d.chain_id == network)
      ?.chain_name || "Unsupported Network";
  return (
    <>
      <Container
        className="px-0 detail-max-width"
        style={{ marginTop: "40px" }}
      >
        <Row>
          <Col>
            <div className="py-1 d-flex align-items-center justify-content-between px-0 detail-header">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row align-items-end">
                  <span>Round 1 Proof ID</span>
                  <span>&nbsp; {taskId}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 " style={{ minHeight: "500px" }}>
        <Row className="task-detail detail-max-width">
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex">Task Overview</Card.Header>
              {/* Task Properties */}
              <Card.Body>
                <Row className="py-1 pb-2 border-bottom g-0">
                  <Col className="col-sm-2 d-flex align-items-center ">
                    Status
                  </Col>
                  <Col className="col-sm-8 flex-row ">
                    <div className="d-flex flex-row align-items-center">
                      <span className="me-2">{batchProofTask.status}</span>
                    </div>
                  </Col>
                </Row>
                <Row className="py-1 pt-2 g-0">
                  <Col className="col-sm-2">Network</Col>
                  <Col className="col-sm-8 text-capitalize">{chainName}</Col>
                </Row>
                <Row className="py-1 pt-2 g-0">
                  <Col className="col-sm-2">Submitted at</Col>
                  <Col className="col-sm-8">
                    {new Date(batchProofTask.batch_started!).toLocaleString()}
                  </Col>
                </Row>
                <Row className="py-1 pt-2 g-0">
                  <Col className="col-sm-2">Completed at</Col>
                  <Col className="col-sm-8">
                    {batchProofTask.batch_finished
                      ? new Date(batchProofTask.batch_finished).toLocaleString()
                      : "Not Finished"}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2 text-decoration-underline">
                    Round 1 Outputs
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  {/* todo: consider if this is necessary? or 
                  need improvement on the structure as round1 queue document has same info as original proof */}
                  <Col className="col-sm-2">Included WASM Proofs</Col>
                  <Col className="col-sm-8">
                    <div className="d-flex flex-column w-auto">
                      {batchProofTask.task_ids.map((id: string) => (
                        <div className="w-auto my-2" key={id}>
                          <Link to={`/task/${id}`}>{id}</Link>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Round 1 Target Instances</Col>
                  <Col className="col-sm-8">
                    {targetInstances &&
                      targetInstances.map((instance, index) => (
                        <ByteDisplay
                          key={index}
                          content={instance}
                        ></ByteDisplay>
                      ))}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">
                    Round 1 Batch Proof Transcripts
                  </Col>
                  <Col className="col-sm-8">
                    {aggregate_proof && (
                      <ByteDisplay content={aggregate_proof}></ByteDisplay>
                    )}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Round 1 Shadow Instances</Col>
                  <Col className="col-sm-8">
                    {shadow_instances && (
                      <ByteDisplay content={shadow_instances}></ByteDisplay>
                    )}
                  </Col>
                </Row>

                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Round 1 Batch Instances</Col>
                  <Col className="col-sm-8">
                    {batchInstances && (
                      <ByteDisplay content={batchInstances}></ByteDisplay>
                    )}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Aux Data</Col>
                  <Col className="col-sm-8">
                    {aux && <ByteDisplay content={aux}></ByteDisplay>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export const FinalBatchProofDetail = () => {
  const appConfig = useAppSelector(selectConfig);
  const { taskId } = useParams<{ taskId: string }>();
  const [aggregate_proof, setAggregate_proof] = useState<BN[] | null>();
  const [shadow_instances, setShadowInstances] = useState<BN[] | null>();
  const [batchInstances, setBatchInstances] = useState<BN[] | null>();
  const [aux, setAux] = useState<BN[] | null>();
  const [targetInstances, setTargetInstances] = useState<BN[][] | null>();

  const [showFlattenedTaskIds, setShowFlattenedTaskIds] =
    useState<boolean>(false);

  const {
    data: task,
    error,
    isLoading,
  } = useGetRound2InfosQuery({
    id: taskId,
    total: 1,
  });

  useEffect(() => {
    if (!task || task.data.length === 0) return;

    if (batchProofTask.proof) {
      setAggregate_proof(ZkWasmUtil.bytesToBN(batchProofTask.proof));
    }
    if (batchProofTask.shadow_instances) {
      setShadowInstances(ZkWasmUtil.bytesToBN(batchProofTask.shadow_instances));
    }

    if (batchProofTask.batch_instances) {
      setBatchInstances(ZkWasmUtil.bytesToBN(batchProofTask.batch_instances));
    }
    if (batchProofTask.aux) {
      setAux(ZkWasmUtil.bytesToBN(batchProofTask.aux));
    }
    if (batchProofTask.target_instances) {
      let input_instances = batchProofTask.target_instances.map((instance) => {
        return ZkWasmUtil.bytesToBN(instance);
      });

      setTargetInstances(input_instances);
    }
  }, [task]);

  // if task is undefined or len 0, return no task found

  if (!task || task.data.length === 0) {
    return <div>No task found</div>;
  }
  // if task is not undefined, get the task from task[0]
  const batchProofTask = task?.data[0];

  const network = batchProofTask.auto_submit_network_chain_id;
  const chainName =
    appConfig.chain_info_list.find((d: any) => d.chain_id == network)
      ?.chain_name || "Unsupported Network";
  return (
    <>
      <Container
        className="px-0 detail-max-width"
        style={{ marginTop: "40px" }}
      >
        <Row>
          <Col>
            <div className="py-1 d-flex align-items-center justify-content-between px-0 detail-header">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-row align-items-end">
                  <span>Round 2 Proof ID</span>
                  <span>&nbsp; {taskId}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 " style={{ minHeight: "500px" }}>
        <Row className="task-detail detail-max-width">
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex">Task Overview</Card.Header>
              {/* Task Properties */}
              <Card.Body>
                <Row className="py-1 pt-2 g-0">
                  <Col className="col-sm-2">Network</Col>
                  <Col className="col-sm-8 text-capitalize">{chainName}</Col>
                </Row>

                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Round 1 Proofs</Col>
                  <Col className="col-sm-8">
                    <div className="d-flex flex-column w-auto">
                      {batchProofTask.round_2_ids.map((id: string) => (
                        <div className="w-auto my-2" key={id}>
                          <Link to={`/batchproof/round2/${id}`}>{id}</Link>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Proof Completed Time</Col>
                  <Col className="col-sm-8">
                    {new Date(batchProofTask.batched_time!).toLocaleString()}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Included Proof Tasks</Col>
                  <Col className="col-sm-8">
                    <div className="d-flex flex-column w-auto">
                      {showFlattenedTaskIds ? (
                        <>
                          <div
                            onClick={() => setShowFlattenedTaskIds(false)}
                            className="text-decoration-underline"
                            role="button"
                          >
                            Hide
                          </div>
                          {batchProofTask.task_ids.map((id: string) => (
                            <div className="w-auto my-2" key={id}>
                              <Link to={`/task/${id}`}>{id}</Link>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div
                            className="text-decoration-underline cursor-pointer"
                            onClick={() => setShowFlattenedTaskIds(true)}
                            role="button"
                          >
                            Show Included Task Ids
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">
                    <span>Target Instances</span>
                  </Col>
                  <Col className="col-sm-8">
                    {targetInstances &&
                      targetInstances.map((instance, index) => (
                        <ByteDisplay
                          key={index}
                          content={instance}
                        ></ByteDisplay>
                      ))}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">
                    <span>Batch Proof Transcripts</span>
                    <div className="mt-2 d-flex">
                      <VerifyBatchProofModal
                        proof={batchProofTask.proof}
                        shadow_instances={batchProofTask.shadow_instances!}
                        aux={batchProofTask.aux}
                        target_instances={batchProofTask.target_instances}
                        verifier_contracts={batchProofTask.verifier_contracts}
                      ></VerifyBatchProofModal>
                    </div>
                  </Col>
                  <Col className="col-sm-8">
                    {aggregate_proof && (
                      <ByteDisplay content={aggregate_proof}></ByteDisplay>
                    )}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Shadow Instances</Col>
                  <Col className="col-sm-8">
                    {shadow_instances && (
                      <ByteDisplay content={shadow_instances}></ByteDisplay>
                    )}
                  </Col>
                </Row>

                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Batch Instances</Col>
                  <Col className="col-sm-8">
                    {batchInstances && (
                      <ByteDisplay content={batchInstances}></ByteDisplay>
                    )}
                  </Col>
                </Row>
                <Row className="pt-2  g-0">
                  <Col className="col-sm-2">Aux Data</Col>
                  <Col className="col-sm-8">
                    {aux && <ByteDisplay content={aux}></ByteDisplay>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
