import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
} from "react-bootstrap";
import {
  useGetNodeInfoQuery,
  useGetProverNodesSummaryQuery,
  useGetOnlineNodesSummaryQuery,
} from "../../data/apiSlice";

import "./style.scss";
import { useAppSelector } from "../../app/hooks";
import { selectConfig } from "../../data/statusSlice";
import { useState } from "react";
import { PaginationJump } from "../PaginationAmount/pagination-jump";

export default function NodeList() {
  const appConfig = useAppSelector(selectConfig);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchAddress, setSearchAddress] = useState<string>("");
  const [queryAddress, setQueryAddress] = useState<string>("");

  const maxNodesPerPage = 25;
  const { data: nodeStats } = useGetNodeInfoQuery({
    start: currentPage * maxNodesPerPage - maxNodesPerPage,
    total: maxNodesPerPage,
    address: queryAddress || undefined,
  });

  const { data: nodesSummary } = useGetProverNodesSummaryQuery({});
  const { data: onlineNodesSummary } = useGetOnlineNodesSummaryQuery({});

  const totalQueryCount = nodeStats?.total || 0;

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxNodesPerPage)) return;

    setCurrentPage(page);
  };

  const handleAddressChange = (addr: string) => {
    // trim any whitespace
    addr = addr.trim();
    setSearchAddress(addr);
  };

  const search = () => {
    // Empty search is ok - it will return all nodes
    if (searchAddress === "") {
      setQueryAddress("");
      return;
    }
    // Check if the address is valid
    if (searchAddress.length !== 42 || !searchAddress.startsWith("0x")) {
      alert("Invalid address");
      return;
    }
    // trigger refetch
    setQueryAddress(searchAddress);
  };

  return (
    <Container style={{ minHeight: "58vh" }}>
      <Container className="mt-5">
        <Row className="task-detail detail-max-width">
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex ">Prover Node Summary</Card.Header>
              {/* Task Properties */}
              <Card.Body>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Certified Nodes</Col>
                  <Col className="col-sm-8">
                    {nodesSummary?.certified_prover_count || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Active Nodes</Col>
                  <Col className="col-sm-8">
                    {nodesSummary?.active_prover_count || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Intern Nodes</Col>
                  <Col className="col-sm-8">
                    {nodesSummary?.intern_prover_count || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Inactive Nodes</Col>
                  <Col className="col-sm-8">
                    {nodesSummary?.inactive_prover_count || 0}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex">Online Nodes</Card.Header>
              {/* Task Properties */}
              <Card.Body>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Certified Nodes</Col>
                  <Col className="col-sm-8">
                    {onlineNodesSummary?.certified.length || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Active Nodes</Col>
                  <Col className="col-sm-8">
                    {onlineNodesSummary?.active.length || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Intern Nodes</Col>
                  <Col className="col-sm-8">
                    {onlineNodesSummary?.intern.length || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col className="col-sm-2">Inactive Nodes</Col>
                  <Col className="col-sm-8">
                    {onlineNodesSummary?.inactive.length || 0}
                  </Col>
                </Row>
                <Row className="py-1 g-0">
                  <Col>
                    <a className="more-button" href="/online_nodes_summary">
                      Details
                    </a>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Form className="d-flex px-0 mt-4 align-items-center">
        <Form.Control
          type="text"
          placeholder="Enter a prover node address with 0x prefix"
          className="me-2 search-bar"
          id="queryMD5"
          aria-label="Search"
          value={searchAddress}
          onChange={(e) => handleAddressChange(e.target.value)}
        />
        <Button
          size="sm"
          className="search-btn "
          onClick={() => {
            search();
          }}
        >
          Search
        </Button>
      </Form>
      <table className="main-table mt-2">
        <thead>
          <tr>
            <th scope="col">Top Node Addresses</th>
            <th scope="col">Successful Tasks</th>
            <th scope="col">Failed Tasks</th>
            <th scope="col">Timed Out Count</th>
            <th scope="col">Total Tasks</th>
            <th scope="col">
              Version - Latest: {appConfig.server_version_info.current_version}
            </th>
          </tr>
        </thead>
        <tbody>
          {nodeStats?.data?.map((node) => {
            return (
              <tr key={node.address}>
                <td
                  scope="row"
                  className="td-address"
                  style={{ padding: "8px 0" }}
                >
                  <a href={`/node?address=${node.address}`}>{node.address}</a>
                </td>
                <td className="td-info">
                  {node.statistics?.successful_tasks || 0}
                </td>
                <td className="td-info">
                  {node.statistics?.failed_tasks || 0}
                </td>
                <td className="td-info">
                  {node.statistics?.timed_out_count || 0}
                </td>
                <td className="td-info">{node.statistics?.total_tasks || 0}</td>
                <td className="td-info">
                  {node.version_info
                    ? isValidNodeVersion(
                        node.version_info.version,
                        appConfig.server_version_info
                          .minimum_supported_node_version
                      )
                      ? node.version_info.version
                      : "Outdated"
                    : "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <PaginationJump
          totalQueryCount={totalQueryCount}
          maxTasksPerPage={maxNodesPerPage}
          setCurrentPage={setCurrentPage}
        ></PaginationJump>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(currentPage - 1)} />

          <Pagination.Item active>
            {currentPage} of {Math.ceil(totalQueryCount / maxNodesPerPage)}
          </Pagination.Item>

          <Pagination.Next onClick={() => handlePagination(currentPage + 1)} />
          <Pagination.Last
            onClick={() =>
              handlePagination(Math.ceil(totalQueryCount / maxNodesPerPage))
            }
          />
        </Pagination>
      </div>
    </Container>
  );
}

// Check the nodes semver against the server's minimum supported version
export function isValidNodeVersion(
  node_version: string,
  server_min_version: string
): boolean {
  let node_version_info = parseSemver(node_version);
  let server_version_info = parseSemver(server_min_version);

  // Check major, minor, patch version numbers
  if (node_version_info.major !== server_version_info.major) {
    return node_version_info.major > server_version_info.major;
  }

  // Compare minor versions
  if (node_version_info.minor !== server_version_info.minor) {
    return node_version_info.minor > server_version_info.minor;
  }

  // Compare patch versions
  return node_version_info.patch >= server_version_info.patch;
}

function parseSemver(semver: string) {
  let semver_parts = semver.split(".");
  return {
    major: semver_parts[0],
    minor: semver_parts[1],
    patch: semver_parts[2],
  };
}
