import React from "react";
import { getLineType } from "../../utils/style";
import { useParams, Link } from "react-router-dom";
import {
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Pagination,
} from "react-bootstrap";
import {
  useGetTaskListQuery,
  useGetImageByHashQuery,
  useGetImageBinaryQuery,
  useUpdateImageMutation,
  useResetImageMutation,
  useDownloadContractsMutation,
} from "../../data/apiSlice";
import { ProofInfoModal } from "../../modals/proofInfo";
import { selectConfig } from "../../data/statusSlice";
import {
  ModifyImageParams,
  ProvePaymentSrc,
  ConciseTask,
  WithSignature,
  ZkWasmUtil,
  AddProveTaskRestrictions,
} from "zkwasm-service-helper";
import AvatarTemp from "../../images/icons/placeholder.png";
import { Time, replaceURLs } from "../../utils/inputs";
import "./style.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectL1Account } from "../../data/accountSlice";
import { TextArea } from "../Inputs";
import { signMessage } from "../../utils/address";
import { ResetImageModal } from "../../modals/resetImage";
import { selectCurrentEndpoint } from "../../data/endpoint";
import { hexlify } from "ethers";
import { ByteDisplay, HexDisplay } from "../TaskDetail";
import { PaginationJump } from "../PaginationAmount/pagination-jump";

export default function ImageDetail() {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectL1Account);
  const endpoint = useAppSelector(selectCurrentEndpoint);
  const params = useParams<{ md5: string }>();
  const pageSizeOptions = [10, 25, 50];
  const [maxTasksPerPage, setMaxTasksPerPage] = React.useState<number>(
    pageSizeOptions[0],
  );
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const handlePageSizeChange = (pageSize: number) => {
    setMaxTasksPerPage(pageSize);
    if (currentPage > Math.floor(totalQueryCount / pageSize + 1)) {
      setCurrentPage(1);
    }
  };

  /*
  const {
    data: tasks,
    isLoading,
    isError,
    isSuccess: loadedTasks,
  } = useGetTasksQuery({
    id: "",
    user_address: "",
    tasktype: "",
    taskstatus: "",
    md5: params.md5!,
  });*/

  const {
    data: image,
    isLoading: loadingImage,
    isSuccess: loadedImage,
    error,
    refetch,
  } = useGetImageByHashQuery(params.md5!, {
    refetchOnFocus: true,
  });

  const [imageSetupTask, setImageSetupTask] = React.useState<ConciseTask>();

  const { data: setupResponse, refetch: refetchSetups } = useGetTaskListQuery({
    user_address: "",
    md5: params.md5!,
    id: "",
    tasktype: "Setup",
    taskstatus: "",
  });

  const setups = setupResponse?.data || [];

  const { data: resetResponse, refetch: refetchResets } = useGetTaskListQuery({
    user_address: "",
    md5: params.md5!,
    id: "",
    tasktype: "Reset",
    taskstatus: "",
  });

  const resets = resetResponse?.data || [];
  const setupAttemps = [...resets, ...setups];

  const [updateImage, result] = useUpdateImageMutation();

  const {
    data: provesResponse,
    isLoading: loadingProves,
    refetch: refetchProofs,
  } = useGetTaskListQuery({
    user_address: "",
    md5: params.md5!,
    id: "",
    tasktype: "Prove",
    taskstatus: "",
    start: maxTasksPerPage * (currentPage - 1),
    total: maxTasksPerPage,
  });

  const proofs = provesResponse?.data || [];
  const totalQueryCount = provesResponse?.total || 0;

  // const { data: deploysResponse, refetch: refetchDeploys } =
  //   useGetTaskListQuery({
  //     user_address: "",
  //     md5: params.md5!,
  //     id: "",
  //     tasktype: "Deploy",
  //     taskstatus: "",
  //   });

  // const deploys = deploysResponse?.data || [];

  /*
  React.useEffect(() => {
    if (!tasks) return;
    let setupTask = tasks.data.find((t) => {
      return t.task_type === "Setup" && t.status === "Done";
    });
    if (!setupTask) {
      setupTask = tasks.data.find((t) => {
        return t.task_type === "Setup";
      });
    }
    console.log("setupTask", setupTask);
    setImageSetupTask(setupTask);
  }, [tasks]);*/
  React.useEffect(() => {
    if (!setupResponse) return;
    if (!imageSetupTask) {
      let setupTask = setupResponse?.data[0];
      console.log("setupTask", setupTask);
      if (setupTask) {
        setImageSetupTask(setupTask);
      }
    }
  }, [setupResponse]);

  const handlePagination = function (page: number) {
    if (page === currentPage) return;
    if (page === 0 && currentPage === 1) return;
    if (page > Math.floor(totalQueryCount / maxTasksPerPage + 1)) return;
    setCurrentPage(page);
  };

  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [newDescription, setNewDescription] = React.useState<string>("");

  const handleUpdateDescription = async () => {
    if (!image) return;
    if (!account) return;
    let updateArgs: ModifyImageParams = {
      md5: image.md5,
      user_address: account!.address.toLowerCase(),
      description_url: newDescription,
      avator_url: image.avator_url,
    };

    try {
      let message = ZkWasmUtil.createModifyImageMessage(updateArgs);
      let signature = await signMessage(message);
      let wSignature: WithSignature<ModifyImageParams> = {
        ...updateArgs,
        signature: signature,
      };
      await updateImage(wSignature);

      setEditMode(false);
      setNewDescription("");
      refetch();
    } catch (error) {}
  };

  const [downloadContracts, downloadStatus] = useDownloadContractsMutation();

  const downloadTarArchive = async () => {
    if (!image) return;
    console.log("Attempting download image");

    try {
      let file = await downloadContracts({ md5: params.md5! }).unwrap();
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contracts.tar");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
    }
  };

  const { data: imageBinary } = useGetImageBinaryQuery(params.md5!);

  const useDownloadWasmImage = async () => {
    if (!image) return;
    console.log("Attempting download wasm image with md5 ", params.md5);

    if (!imageBinary) {
      console.log("Failed to retrieve image binary");
      return;
    }

    try {
      const fileContent = new Uint8Array(imageBinary!);
      const blob = new Blob([fileContent]);
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = params.md5! + ".wasm";

      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const appConfig = useAppSelector(selectConfig);
  const parseAutoSubmitNetworks = function (networks: number[]): string {
    if (networks.length === 0) {
      return "None";
    }
    return networks
      .map((id) => {
        return appConfig.chain_info_list.find((it) => it.chain_id == id)
          ?.chain_name!;
      })
      .join(", ");
  };

  return (
    <>
      {loadedImage && image ? (
        <>
          <Container className="mb-2 mt-4 image-container">
            <Row>
              <Col>
                <div className="py-2 d-flex align-items-end justify-content-between px-0 page-header">
                  <div className="d-flex align-items-end">
                    <img
                      src={image.avator_url ? image.avator_url : AvatarTemp}
                      alt="avatar"
                      className="avatar"
                    />
                    <div className="d-flex flex-row align-items-center ">
                      <span className="image-heading">&nbsp; Image Hash </span>
                      <span className="image-hash">&nbsp; {image.md5}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="me-2 user-address">
                      {image.user_address}
                    </span>
                    <a
                      href={`https://www.etherscan.io/address/${image.user_address}`}
                      target="_blank"
                    >
                      <i
                        className="bi bi-box-arrow-up-right"
                        role="View on Etherscan"
                      ></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="description-info image-container">
            <Row>
              <Col>
                <Card className="px-4 py-2 info-card">
                  <div className="d-flex align-items-center justify-content-between pb-2">
                    <h5 className="mb-0">Overview</h5>
                    <div className="d-flex align-items-center">
                      {image.user_address ===
                        account?.address.toLowerCase() && (
                        <ResetImageModal md5={params!.md5!}></ResetImageModal>
                      )}
                    </div>
                  </div>

                  <Row className="info-row">
                    <Col className="col-4">Owner:</Col>
                    <Col className="col-8">{image.user_address}</Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Created on:</Col>
                    <Col className="col-8">
                      {imageSetupTask ? (
                        <>
                          <Time timestr={imageSetupTask.submit_time}></Time>
                        </>
                      ) : (
                        <>Loading...</>
                      )}
                    </Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Auto Submit Proof Network(s):</Col>
                    <Col className="col-8">
                      <span>
                        {parseAutoSubmitNetworks(image.auto_submit_network_ids)}
                      </span>
                    </Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Circuit Size:</Col>
                    <Col className="col-8">
                      <span>{image.circuit_size}</span>
                    </Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Creator Paid Proof:</Col>
                    <Col className="col-8">
                      <span>
                        {image.prove_payment_src === ProvePaymentSrc.CreatorPay
                          ? "Yes"
                          : "No"}
                      </span>
                    </Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">
                      Only image creator can add prove task:
                    </Col>
                    <Col className="col-8">
                      <span>
                        {image.add_prove_task_restrictions ===
                        AddProveTaskRestrictions.CreatorOnly
                          ? "Yes"
                          : "No"}
                      </span>
                    </Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Proofs submitted:</Col>
                    <Col className="col-8">{totalQueryCount}</Col>
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Image Commitment: </Col>
                    {image.checksum ? (
                      <>
                        <Col className="col-8">
                          <div>
                            X:
                            <span className="ms-2">
                              {/* https://github.com/ethers-io/ethers.js/issues/4436 */}
                              {hexlify(new Uint8Array(image.checksum.x))}
                            </span>
                          </div>
                          <div className="mt-2">
                            Y:
                            <span className="ms-2">
                              {hexlify(new Uint8Array(image.checksum.y))}
                            </span>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col>No Commitment for Image</Col>
                      </>
                    )}
                  </Row>

                  <Row className="info-row">
                    <Col className="col-4">Image Status:</Col>
                    <Col className="col-8">
                      <span>{image.status}</span>
                    </Col>
                  </Row>

                  <Row
                    className="info-row"
                    hidden={!image.inherited_merkle_data_info}
                  >
                    <Col className="col-4">Shared Data Image:</Col>
                    <Col className="col-8">
                      <span>
                        {image.inherited_merkle_data_info
                          ? image.inherited_merkle_data_info.md5
                          : "N/A"}
                      </span>
                    </Col>
                  </Row>

                  <div className="d-flex align-items-center justify-content-between pb-2">
                    <h5 className="mb-0"></h5>
                    <div className="d-flex align-items-center">
                      {image &&
                        image.user_address ===
                          account?.address.toLowerCase() && (
                          <button
                            className="verify-proof-btn"
                            onClick={useDownloadWasmImage}
                          >
                            Download Image
                          </button>
                        )}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card className="px-4 py-2 info-card description">
                  <h5 className="pb-2 d-flex justify-content-between align-items-center">
                    <span>Description</span>
                    {image.user_address === account?.address.toLowerCase() && (
                      <>
                        <div
                          className="edit-button"
                          onClick={() => setEditMode(true)}
                        >
                          <i className="bi bi-pencil-square"></i>{" "}
                          <span className="small">Edit</span>
                        </div>
                      </>
                    )}
                  </h5>
                  {editMode && result.isLoading && (
                    <>
                      <div className="info-row">
                        <Spinner></Spinner>
                      </div>
                    </>
                  )}
                  {(result.isUninitialized || result.isSuccess) &&
                    !editMode && (
                      <>
                        <div className="info-row">
                          {replaceURLs(
                            image.description_url || "No description provided.",
                          ).map((e, index) => {
                            return (
                              <React.Fragment key={index}>{e}</React.Fragment>
                            );
                          })}
                        </div>
                      </>
                    )}

                  {
                    <div>
                      {editMode && !result.isLoading && (
                        <>
                          <TextArea
                            className="w-100"
                            value={newDescription}
                            rows={3}
                            onChange={(e) => setNewDescription(e.target.value)}
                          ></TextArea>
                          <div>
                            <button
                              className="confirm-btn"
                              onClick={() => handleUpdateDescription()}
                            >
                              Save
                            </button>
                            <button
                              className="confirm-btn"
                              onClick={() => {
                                setEditMode(false);
                                setNewDescription("");
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </Card>
              </Col>
            </Row>
          </Container>
          {/* Deployments */}
          {/* <Container className="deployments image-container">
            <div className="section-header">Deployments</div>
            <table className="main-table image-detail-table">
              <thead>
                <tr>
                  <th scope="col">Chain Name</th>
                  <th scope="col">Deployed Chain ID</th>
                  <th scope="col">Verifier Contract Address</th>
                </tr>
              </thead>
              <tbody>
                {verifierDeployments.map((d, index) => {
                  let config = getChainConfigFromChainId(d.chain_id);
                  return (
                    <React.Fragment key={index}>
                      <tr key={index}>
                        <td>{config?.chain_name}</td>
                        <td>
                          <span>{d.chain_id}</span>
                        </td>
                        <td style={{ height: "40px" }}>
                          <span className="me-2">{d.address}</span>
                          <a
                            href={`${config?.block_explorer_url}/address/${d.address}`}
                            target="_blank"
                          >
                            <i
                              className="bi bi-box-arrow-up-right"
                              role="View on Etherscan"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination-wrapper"></div>
          </Container> */}
          {/* Proofs Tasks*/}
          <Container className="proofs image-container">
            <div className="d-flex">
              <div className="section-header">Proofs Submitted</div>
              {/* {!downloadStatus.isLoading && (
                <div
                  className="download-button section-header"
                  onClick={() => {
                    downloadTarArchive();
                  }}
                >
                  Download Verifier Contracts <i className="bi bi-download"></i>
                </div>
              )}
              {downloadStatus.isLoading && (
                <>
                  <Spinner></Spinner>
                </>
              )}
              {downloadStatus.isError && (
                <div className="download-error d-flex align-items-center">
                  Error downloading: {downloadStatus.error as string}
                </div>
              )} */}
            </div>

            {loadingProves ? (
              <>
                <Row>
                  <Spinner></Spinner>
                </Row>
              </>
            ) : (
              <>
                <table className="main-table image-detail-table">
                  <thead>
                    <tr>
                      <th>Task ID</th>
                      {/* <th>Proof Details</th> */}
                      <th>Process Started</th>
                      <th>Process Finished</th>
                      <th>Process Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {proofs.map((d, index) => {
                      let processTime = null;
                      if (d.process_started && d.process_finished) {
                        processTime =
                          (new Date(Date.parse(d.process_finished)).getTime() -
                            new Date(Date.parse(d.process_started)).getTime()) /
                          1000;
                        if (processTime < 0) {
                          processTime = null;
                        }
                      }

                      return (
                        <React.Fragment key={d._id["$oid"]}>
                          <tr>
                            <td>
                              <Link
                                to={`/task/${d._id["$oid"]}`}
                                //className="task-id"
                              >
                                {d._id["$oid"]}
                              </Link>
                            </td>
                            {/* <td className="min-width-md">
                              <ProofInfoModal task={d}></ProofInfoModal>
                            </td> */}
                            <td className="td-info ">
                              {d.process_started ? (
                                <Time timestr={d.process_started}></Time>
                              ) : (
                                <span>Not started</span>
                              )}
                            </td>
                            <td className="td-info ">
                              {d.process_finished ? (
                                <Time timestr={d.process_finished}></Time>
                              ) : (
                                <span>Not finished</span>
                              )}
                            </td>
                            <td className="td-info ">
                              {processTime ? processTime : "N/A"} seconds
                            </td>
                            <td className="min-width-xs">
                              <div
                                className={`task-${getLineType(
                                  d.status,
                                )}-badge`}
                              >
                                {d.status}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination-wrapper">
                  <PaginationJump
                    totalQueryCount={totalQueryCount}
                    maxTasksPerPage={maxTasksPerPage}
                    setCurrentPage={handlePagination}
                  ></PaginationJump>
                  <Pagination>
                    <Pagination.First onClick={() => handlePagination(1)} />
                    <Pagination.Prev
                      onClick={() => handlePagination(currentPage - 1)}
                    />
                    <Pagination.Item active>
                      {currentPage} of{" "}
                      {Math.ceil(totalQueryCount / maxTasksPerPage)}
                    </Pagination.Item>
                    <Pagination.Next
                      onClick={() => handlePagination(currentPage + 1)}
                    />
                    <Pagination.Last
                      onClick={() =>
                        handlePagination(
                          Math.ceil(totalQueryCount / maxTasksPerPage),
                        )
                      }
                    />
                    {/* <PaginationAmount
                  pageSizeOptions={pageSizeOptions}
                  onPageSizeChange={handlePageSizeChange}
                ></PaginationAmount> */}
                  </Pagination>
                </div>
              </>
            )}
            {/* <Card>
              <Card.Body
                className={`${
                  loadingProves
                    ? " d-flex justify-content-center align-items-center"
                    : ""
                } ${
                  totalQueryCount > maxTasksPerPage ? "table-container" : ""
                }`}
              ></Card.Body>
            </Card> */}
          </Container>
          <Container className="proofs image-container">
            <div className="section-header">Setup Information</div>
            <table className="main-table image-detail-table">
              <thead>
                <tr>
                  <th>Task ID</th>

                  <th>Process Started</th>
                  <th>Process Finished</th>
                  <th>Process Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {setupAttemps.map((d) => {
                  let processTime = null;
                  if (d.process_started && d.process_finished) {
                    processTime =
                      (new Date(Date.parse(d.process_finished)).getTime() -
                        new Date(Date.parse(d.process_started)).getTime()) /
                      1000;
                  }

                  return (
                    <React.Fragment key={d._id["$oid"]}>
                      <tr>
                        <td>
                          <Link
                            to={`/task/${d._id["$oid"]}`}
                            //className="task-id"
                          >
                            {d._id["$oid"]}
                          </Link>
                        </td>
                        <td className="td-info min-width">
                          {d.process_started ? (
                            <Time timestr={d.process_started}></Time>
                          ) : (
                            <span>Not started</span>
                          )}
                        </td>
                        <td className="td-info ">
                          {d.process_finished ? (
                            <Time timestr={d.process_finished}></Time>
                          ) : (
                            <span>Not finished</span>
                          )}
                        </td>
                        <td className="td-info min-width">
                          {processTime} seconds
                        </td>
                        <td className="min-width">
                          <div
                            className={`task-${getLineType(d.status)}-badge`}
                          >
                            {d.status}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination-wrapper"></div>

            <Row className="info-row mt-4">
              <Col className="col-2">
                <div className="section-header">Context Information</div>
              </Col>
              <Col className="col-8">
                {image.context ? (
                  <HexDisplay bytes={image.context}></HexDisplay>
                ) : (
                  "No context found for this image"
                )}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Container
            style={{ minHeight: "65vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            {loadingImage ? (
              <Row>
                <Col>
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>No Image found with this hash.</Col>
              </Row>
            )}
          </Container>
        </>
      )}
    </>
  );
}
