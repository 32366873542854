import { TaskStatus } from "zkwasm-service-helper";

export function getLineType(st: TaskStatus): string {
  if (st === "Fail") {
    return "fail";
  } else if (st === "Done") {
    return "success";
  } else if (st === "Stale") {
    return "stale";
  } else if (st === "DryRunFailed") {
    return "dryrunfail";
  } else if (st === "Unprovable") {
    return "unprovable";
  } else {
    return "pending";
  }
}
