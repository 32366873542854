import {
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import {
  useGetOnlineNodesSummaryQuery,
} from "../../data/apiSlice";
import { Time } from "../../utils/inputs";

export default function OnlineNodesSummaryPage() {
  const { data: onlineNodesSummary } = useGetOnlineNodesSummaryQuery({});
  return (
    <Container style={{ minHeight: "58vh" }}>
      <Container className="mt-5">
      </Container>
      <Container className="mt-5">
        <Row className="task-detail detail-max-width">
          <Col>
            <Card className="tx-detail-body">
              <Card.Header className="d-flex ">Online Node Summary</Card.Header>
            </Card>
          </Col>
        </Row>
      </Container>
      {[
        [onlineNodesSummary?.certified || [], "Certified"],
        [onlineNodesSummary?.active || [], "Active"],
        [onlineNodesSummary?.intern || [], "Intern"],
        [onlineNodesSummary?.inactive || [], "Inactive"],
      ].map((perLevelIter) => (
        <table className="main-table mt-2" key={`${perLevelIter[1]}`}>
          <thead>
            <tr key={`${perLevelIter[1]}`}>
              <th style={{ width: "75%" }} scope="col">{`${perLevelIter[1]} Addresses`}</th>
              <th style={{ width: "25%" }} scope="col">Last Online</th>
            </tr>
          </thead>
          <tbody>
            {(perLevelIter[0] as [string, string][]).map((perNodeInfoIter) => {
              return (
                <tr key={perNodeInfoIter[0]}>
                  <td
                    scope="row"
                    className="td-address"
                    style={{ padding: "8px 0" }}
                  >
                    <a href={`/node?address=${perNodeInfoIter[0]}`}>{perNodeInfoIter[0]}</a>
                  </td>
                  <td className="td-info">
                    <Time timestr={perNodeInfoIter[1]}></Time>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ))}
    </Container>
  );
}
