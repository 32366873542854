import { ZkWasmUtil, CompressionType } from "zkwasm-service-helper";

interface DownloadProps {
  bytes: Uint8Array;
  filename: string;
  compression: CompressionType | undefined;
}

function DownloadJsonButton({ bytes, filename, compression }: DownloadProps) {
  const handleDownloadJson = () => {
    const outFilename =
      // Assume gzip is also tar for now
      filename + (compression === CompressionType.GZip ? ".tar.gz" : ".json");
    const data =
      compression === CompressionType.GZip
        ? bytes.buffer
        : ZkWasmUtil.bytesToJSONString(bytes);
    const blob = new Blob([data], {
      type:
        compression === CompressionType.GZip
          ? "application/gzip"
          : "application/json",
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = outFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <button onClick={handleDownloadJson} className="verify-proof-btn">
      Download External Host Table Data
    </button>
  );
}

export default DownloadJsonButton;
