import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectConfig, loadConfig } from "../data/statusSlice";
import {
  useLazyGetNodeInfoQuery,
  useLazyGetTaskListQuery,
  zkWASMApi,
} from "../data/apiSlice";

import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import {
  loginL1AccountAsync,
  selectL1Account,
  loadUser,
  selectUserInfo,
  selectSubscription,
  loadSubscription,
} from "../data/accountSlice";
import { getLineType } from "../utils/style";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Col,
  Pagination,
  Spinner,
  ToastContainer,
  Toast,
  FormGroup,
} from "react-bootstrap";

import { Receipts, selectReceipts } from "./layoutSlice";
import { Task, QueryParams } from "zkwasm-service-helper";
import { Time } from "../utils/inputs";
import { ProofInfoModal } from "../modals/proofInfo";
import { ImageInfoModal } from "../modals/imageInfo";
import { NewWASMImage } from "../modals/addNewImage";
import { NewProveTask } from "../modals/addNewProveTask";
import AddCustomURL from "../modals/addCustomURL";
import {
  addressAbbreviation,
  getWalletState,
  setWalletState,
} from "../utils/address";
import logo from "../images/logo.png";
import Footer from "./Foot";
import Statistics from "../components/Statistics";
import ImageDetail from "../components/ImageDetail";
import TaskDetail from "../components/TaskDetail";
import UserDetail from "../components/UserDetail";
import InstructionTable from "../components/InstructionTable";
import CustomCard from "../components/Card";
import WalletIcon from "../assets/imgs/wallet_icon.png";
import TickCircle from "../images/icons/tick_circle.png";
import UpArrow from "../images/icons/up_arrow.png";
import {
  updateCurrentEndpoint,
  selectCurrentEndpoint,
  Endpoint,
  selectEndpointList,
  defaultEndpoint,
  setEndpointList,
} from "../data/endpoint";
import "bootswatch/dist/slate/bootstrap.min.css";
import "./style.scss";

import NodeList from "../components/NodeList";
import OnlineNodesSummaryPage from "../components/OnlineNodesSummaryPage";

import Subscription from "../components/Subscriptions";
import {
  FinalTable,
  Round1Table,
  Round2Table,
} from "../components/BatchProofList";
import {
  FinalBatchProofDetail,
  Round2BatchProofDetail,
} from "../components/BatchProofDetail";
import { PaginationJump } from "../components/PaginationAmount/pagination-jump";
import NodeDetail from "../components/NodeDetail";

const INTERVAL_ONE_SEC = 1000;
const TASK_LIST_POLLING_INTERVAL_MSEC = 60 * INTERVAL_ONE_SEC;
const TOO_MANY_REQUESTS_ERR_CODE = "Too many requests";
const MAX_TASKS_PER_PAGE = 5;

function handleUnknownError(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "object" && error !== null) {
    return JSON.stringify(error);
  } else {
    return String(error);
  }
}

const useInitLoad = (): boolean => {
  const [isInitLoad, setIsInitLoad] = useState(true);
  useEffect(() => {
    if (isInitLoad) {
      setIsInitLoad(false);
    }
  }, [isInitLoad]);
  return isInitLoad;
};

function MainNavBar() {
  const dispatch = useAppDispatch();
  let account = useAppSelector(selectL1Account);
  let userInfo = useAppSelector(selectUserInfo);
  let subscriptionInfo = useAppSelector(selectSubscription);
  const endpoints = useAppSelector(selectEndpointList);
  const currentEndpoint = useAppSelector(selectCurrentEndpoint);

  const changeProvider = (endpoint: Endpoint) => {
    dispatch(updateCurrentEndpoint(endpoint));

    dispatch(zkWASMApi.util.resetApiState());
  };

  const handleRemoveEndpoint = (nickname: string) => {
    const newEndpoints = endpoints.filter(
      (endpoint) => endpoint.nickname !== nickname
    );
    dispatch(setEndpointList(newEndpoints));
    //set current endpoint to default if it is removed
    if (currentEndpoint.nickname === nickname) {
      changeProvider(defaultEndpoint);
    }
  };

  useEffect(() => {
    // Try auto connect if account is already connected
    const walletState = getWalletState();

    if (walletState?.connected) {
      console.log(account, walletState);
      dispatch(loginL1AccountAsync());
    }
  }, []);

  useEffect(() => {
    // Update wallet state when account changes
    if (account) {
      setWalletState({ connected: true });
    } else {
      setWalletState({ connected: false });
    }
  }, [account]);

  return (
    <Navbar expand="lg" style={{ zIndex: "1000" }} className="nav-text">
      <Container className="nav-container">
        <Navbar.Brand href="/" className="border-0 ps-0" target="_self">
          <img
            src={logo}
            alt=""
            style={{ width: "67px", height: "19px" }}
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div role="button" className="nav-link d-flex align-items-center">
              <NewWASMImage></NewWASMImage>
            </div>
            <div role="button" className="nav-link d-flex align-items-center">
              <NewProveTask></NewProveTask>
            </div>

            <NavDropdown
              title="Select PAAS Provider"
              id="basic-nav-dropdown"
              menuRole="menu"
              className="d-flex align-items-center background-none"
            >
              <NavDropdown.Item
                onClick={() => changeProvider(defaultEndpoint)}
                key={defaultEndpoint.url}
              >
                {defaultEndpoint.nickname}&nbsp;
                {currentEndpoint.nickname === defaultEndpoint.nickname
                  ? "(Selected)"
                  : ""}
              </NavDropdown.Item>
              {endpoints.map((endpoint) => {
                return (
                  <NavDropdown.Item
                    onClick={() => changeProvider(endpoint)}
                    key={endpoint.url}
                    className="d-flex align-items-center justify-content-between"
                  >
                    {endpoint.nickname}&nbsp;
                    {currentEndpoint.nickname === endpoint.nickname
                      ? "(Selected)"
                      : ""}
                    <i
                      className="bi bi-trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveEndpoint(endpoint.nickname);
                      }}
                    ></i>
                  </NavDropdown.Item>
                );
              })}
              <NavDropdown.Divider></NavDropdown.Divider>
              <NavDropdown.Item>
                <AddCustomURL></AddCustomURL>
              </NavDropdown.Item>
            </NavDropdown>
            {account && (
              <>
                <Link
                  to={`/user/${account.address}`}
                  className="account-info d-flex align-items-center"
                >
                  <i className="bi bi-person  circle-surround me-1"></i>
                  {addressAbbreviation(account.address, 4)}
                </Link>
                <div className="d-flex align-items-center">
                  {userInfo && (
                    <div className="balance-info">
                      <i className="bi bi-wallet me-0 ms-2"></i>
                      <span className="ms-1 font-weight-bold label">
                        Balance:{" "}
                      </span>
                      <span className="text-white amount">
                        &nbsp;
                        {BigInt(userInfo.credits).toString()} Credits
                      </span>
                      {userInfo.credit_deficit && BigInt(userInfo.credit_deficit) !== BigInt(0) &&
                        (<span className="amount" style={{ color: 'lightcoral', marginLeft: '5px' }}>
                          &nbsp;
                          {BigInt(userInfo.credit_deficit).toString()} Deficit
                          </span>)
                      }
                    </div>
                  )}

                  {subscriptionInfo && (
                    <div className="subscription-info ms-2">
                      <img src={TickCircle}></img>
                      <span className="ms-1 font-weight-bold label">
                        Subscription:{" "}
                      </span>
                      <span className="text-white amount">
                        {subscriptionInfo.params.subscription_type}
                      </span>
                    </div>
                  )}

                  <span className="topup ms-2">
                    <Link className="topup-button" to="/subscribe">
                      <img src={UpArrow}></img>{" "}
                      <span className="ms-1">Charge</span>
                    </Link>
                  </span>
                </div>
              </>
            )}

            {!account && (
              <div
                onClick={() => dispatch(loginL1AccountAsync())}
                className="d-flex justify-content-center align-items-center ms-2"
              >
                <div className="connect-account">
                  <img
                    src={WalletIcon}
                    alt="wallet"
                    style={{ maxWidth: "12px" }}
                  />
                  <span className="ms-2">Connect Wallet</span>
                </div>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function ReceiptNavBar() {
  const receipts = useAppSelector(selectReceipts);
  const [showToasts, setShowToasts] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setShowToasts((prevShowToasts) => {
      const newShowToasts: Record<string, boolean> = { ...prevShowToasts };

      receipts.forEach((receipt: Receipts) => {
        if (prevShowToasts[receipt.id] === undefined)
          newShowToasts[receipt.id] = true;
      });

      return newShowToasts;
    });
  }, [receipts]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        {receipts.map((receipt) => {
          console.log(receipt);
          return (
            <Toast
              key={receipt.id}
              bg="success"
              onClose={() =>
                setShowToasts((prev) => ({ ...prev, [receipt.id]: false }))
              }
              show={showToasts[receipt.id]}
              delay={5000}
              autohide
              animation={true}
            >
              <Toast.Header className="justify-content-between">
                New Task!
              </Toast.Header>
              <Toast.Body>
                Task <Link to={`/task/${receipt.id}`}>{receipt.id}</Link>{" "}
                created for wasm application image{" "}
                <Link to={`/image/${receipt.md5}`}>{receipt.md5}</Link>
              </Toast.Body>
            </Toast>
          );
        })}
      </ToastContainer>
    </>
  );
}

export interface MoreInfoProps {
  task: Task;
}

export function MoreInfoModal(info: MoreInfoProps) {
  let task = info.task;
  if (task.task_type === "Setup" || task.task_type === "Reset") {
    return <ImageInfoModal md5={info.task.md5}></ImageInfoModal>;
  } else if (task.task_type === "Deploy") {
    return <ImageInfoModal md5={info.task.md5}></ImageInfoModal>;
  } else if (task.task_type === "Prove") {
    return <ProofInfoModal task={info.task}></ProofInfoModal>;
  } else {
    return <>NA</>;
  }
}

const taskTypes = [
  {
    name: "All",
    link: "/",
  },
  {
    name: "Setup",
    link: "/setup",
  },
  {
    name: "Prove",
    link: "/prove",
  },
  {
    name: "Deploy",
    link: "/deploy",
  },
];

const taskStatuses = ["All", "Pending", "Processing", "Done", "Fail"];

export function Main() {
  const dispatch = useAppDispatch();
  const receipts = useAppSelector(selectReceipts);
  const currentEndpoint = useAppSelector(selectCurrentEndpoint);
  const account = useAppSelector(selectL1Account);

  const [typeFilter, setTypeFilter] = useState<string>("All");
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const pageSizeOptions = [10, 25, 50];
  const [maxTasksPerPage, _] = React.useState<number>(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  let [query, setQuery] = useState<QueryParams>({
    user_address: "",
    md5: "",
    id: "",
    tasktype: "",
    taskstatus: "",
    start: maxTasksPerPage * (currentPage - 1),
    total: maxTasksPerPage,
  });
  const [queryString, setQueryString] = useState("");
  const [queryType, setQueryType] = useState<string>("md5"); //default to md5 search

  const handlePagination = function (page: number) {
    if (page < 1 || page > Math.ceil(totalQueryCount / maxTasksPerPage)) return;

    setCurrentPage(page);
    //Refetch tasks with new page number (in useEffect on query change)
    setQuery({ ...query, start: maxTasksPerPage * (page - 1) });
  };

  const handleSearch = (searchString: string) => {
    //Parse the search string to determine whether input is an address or an md5 hash
    setQueryString(searchString);
    if (searchString.length === 42 && searchString.startsWith("0x")) {
      console.log("address", searchString);
      return setQueryType("user_address");
    }
    if (searchString.length === 32) {
      console.log("md5", searchString);
      return setQueryType("md5");
    }
    if (searchString.length === 24) {
      console.log("id", searchString);
      return setQueryType("id");
    }
    //Reset query type if search string is empty and cause refetch
    if (searchString.length === 0) {
      setQuery((prev) => {
        return { ...prev, user_address: "", md5: "", id: "" };
      });
    } else {
      //If search string is not an address or an md5 hash or ObjectID, set to md5
      //but do not refetch
      setQueryType("md5");
    }
  };

  function search() {
    //Search will ignore the tasktype and status filter
    let customQuery = {
      user_address: "",
      md5: "",
      id: "",
      tasktype: "",
      taskstatus: "",
    };
    customQuery[queryType as keyof typeof customQuery] = queryString;

    let q = {
      ...customQuery,
      start: 0,
      total: maxTasksPerPage,
    };
    if (
      (queryType === "user_address" && q.user_address === query.user_address) ||
      (queryType === "md5" && q.md5 === query.md5) ||
      (queryType === "id" && q.id === query.id)
    ) {
      return;
    }
    setCurrentPage(1);
    setQuery(q);
  }

  const [
    triggerTasksData,
    {
      data: tasksData,
      isLoading: loadingTasks,
      isSuccess,
      isError: loadingError,
      error: loadingErrorInfo,
    },
  ] = useLazyGetTaskListQuery({
    pollingInterval: TASK_LIST_POLLING_INTERVAL_MSEC,
  });
  const { data: tasks, total: totalQueryCount } =
    isSuccess && tasksData ? tasksData : { data: [], total: 0 };

  const [triggerSetupTaskData, { data: setupResponse }] =
    useLazyGetTaskListQuery({
      // Offset the polling interval by 2 secs to ensure queries don't all fire at same time.
      pollingInterval: TASK_LIST_POLLING_INTERVAL_MSEC + 2 * INTERVAL_ONE_SEC,
    });
  const setups = setupResponse?.data || [];

  const [triggerProofTaskData, { data: provesResponse }] =
    useLazyGetTaskListQuery({
      pollingInterval: TASK_LIST_POLLING_INTERVAL_MSEC + 4 * INTERVAL_ONE_SEC,
    });
  const proves = provesResponse?.data || [];

  const [triggerNodeStats, nodeStats] = useLazyGetNodeInfoQuery({
    pollingInterval: TASK_LIST_POLLING_INTERVAL_MSEC + 6 * INTERVAL_ONE_SEC,
  });

  const executeQueries = async () => {
    triggerSetupTaskData({
      user_address: "",
      md5: "",
      id: "",
      tasktype: "Setup",
      taskstatus: "",
      start: 0,
      total: MAX_TASKS_PER_PAGE,
    });
    triggerProofTaskData({
      user_address: "",
      md5: "",
      id: "",
      tasktype: "Prove",
      taskstatus: "",
      start: 0,
      total: MAX_TASKS_PER_PAGE,
    });
    triggerTasksData(query);
    triggerNodeStats({ start: 0, total: MAX_TASKS_PER_PAGE });
    dispatch(loadUser());
  };

  const isInitLoad = useInitLoad();
  useEffect(() => {
    // when receipts updates, send a refetch to update the tasks after
    const timer = setTimeout(async () => {
      if (isInitLoad) {
        return;
      }
      executeQueries();
    }, 5 * INTERVAL_ONE_SEC);
    return () => clearTimeout(timer);
  }, [receipts]);

  useEffect(() => {
    const executeTasksData = async () => {
      if (isInitLoad) {
        return;
      }
      triggerTasksData(query);
    };

    executeTasksData();
  }, [query]);

  useEffect(() => {
    dispatch(loadConfig());
  }, [currentEndpoint]);

  useEffect(() => {
    dispatch(loadUser());
    dispatch(loadSubscription());
  }, [account]);

  useEffect(() => {
    if (isInitLoad) {
      executeQueries();
    }
  }, [isInitLoad]);

  return (
    <>
      <MainNavBar></MainNavBar>
      <ReceiptNavBar></ReceiptNavBar>
      <Routes>
        {/* TODO: Move the home page into a component */}
        <Route
          path="/"
          element={
            <>
              <div className="img-bg "></div>
              <Container
                className="d-flex flex-column main-content position-relative px-0"
                style={{ zIndex: "1" }}
              >
                <div className="content-wrapper">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="h1 text-white m-0">
                      The ZKWASM Task Explorer
                    </h1>

                    <div className="d-flex ">
                      <FormGroup className="d-flex flex-column align-items-start me-2">
                        {/* <Form.Label>Task Type:</Form.Label> */}
                        <Form.Select
                          aria-label="Default select example"
                          size="sm"
                          value={typeFilter}
                          onChange={(e) => {
                            setTypeFilter(e.target.value);
                            setQuery((prev) => {
                              return {
                                ...prev,
                                tasktype:
                                  e.target.value === "All"
                                    ? ""
                                    : e.target.value,
                              };
                            });
                          }}
                        >
                          {taskTypes.map((t) => {
                            return (
                              <option value={t.name} key={t.name}>
                                {t.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </FormGroup>
                      <FormGroup className="d-flex flex-column align-items-start">
                        {/* <Form.Label>Task Status:</Form.Label> */}
                        <Form.Select
                          aria-label="Default select example"
                          size="sm"
                          value={statusFilter}
                          className="custom-line"
                          onChange={(e) => {
                            setStatusFilter(e.target.value);
                            setQuery((prev) => {
                              return {
                                ...prev,
                                taskstatus:
                                  e.target.value === "All"
                                    ? ""
                                    : e.target.value,
                              };
                            });
                          }}
                        >
                          {taskStatuses.map((t) => {
                            return (
                              <option value={t} key={t}>
                                {t}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </FormGroup>
                    </div>
                  </div>

                  <Form className="d-flex px-0 mt-3 align-items-center">
                    <Form.Control
                      type="text"
                      placeholder="Enter an MD5 hash, 0x address, or task ID"
                      className="me-2 search-bar"
                      id="queryMD5"
                      aria-label="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Button
                      size="sm"
                      className="search-btn "
                      onClick={() => {
                        search();
                      }}
                    >
                      Search
                    </Button>
                  </Form>
                </div>
              </Container>

              <Statistics></Statistics>
              <Container
                className="main-list px-0 "
                style={{ paddingTop: "30px" }}
              >
                <Row className="mb-4">
                  <Col>
                    <CustomCard
                      header="Latest Setups"
                      headerProps={{
                        className: "aqua",
                      }}
                    >
                      {setups.map((task, index) => {
                        if (index > 5) return;
                        let hash = task.md5;
                        return (
                          <div
                            key={task._id["$oid"]}
                            className="px-0 task-detail-row "
                          >
                            <Row className="my-1">
                              <Col className="d-flex align-items-center justify-content-between task-latest-row">
                                <span className=" px-2 span-background-setup">
                                  <span className="span-color-md5">
                                    MD5&nbsp;
                                  </span>
                                  <Link
                                    to={`/image/${hash}`}
                                    className="link-color-hash"
                                  >
                                    {addressAbbreviation(hash, 6)}
                                  </Link>
                                </span>
                                <div>
                                  <Link to={`/user/${task.user_address}`}>
                                    {addressAbbreviation(task.user_address, 4)}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center justify-content-between">
                                <span>
                                  <span>TaskID </span>
                                  <Link to={`/task/${task._id["$oid"]}`}>
                                    {task._id["$oid"]}
                                  </Link>
                                </span>
                                <span>
                                  <Time timestr={task.submit_time}></Time>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </CustomCard>
                  </Col>
                  <Col>
                    <CustomCard
                      header="Latest Proofs"
                      headerProps={{
                        className: "light-blue",
                      }}
                    >
                      {proves.map((task, index) => {
                        if (index > 5) return;
                        let hash = task.md5;
                        return (
                          <div
                            key={task._id["$oid"]}
                            className="px-0 task-detail-row "
                          >
                            <Row className="my-1">
                              <Col className="d-flex align-items-center justify-content-between task-latest-row">
                                <span className="px-2 span-background-proof">
                                  <span className="span-color-md5">
                                    MD5&nbsp;
                                  </span>
                                  <Link
                                    to={`/image/${hash}`}
                                    className="link-color-hash"
                                  >
                                    {addressAbbreviation(hash, 6)}
                                  </Link>
                                </span>
                                <div>
                                  <Link to={`/user/${task.user_address}`}>
                                    {addressAbbreviation(task.user_address, 4)}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center justify-content-between">
                                <span>
                                  <span>TaskID </span>
                                  <Link to={`/task/${task._id["$oid"]}`}>
                                    {task._id["$oid"]}
                                  </Link>
                                </span>
                                <span>
                                  <Time timestr={task.submit_time}></Time>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </CustomCard>
                  </Col>
                  {/* <Col>
                    <CustomCard
                      header="Latest Deployments"
                      headerProps={{
                        className: "lighter-blue",
                      }}
                    >
                      {deploys.map((task, index) => {
                        if (index > 5) return;
                        let hash = task.md5;
                        return (
                          <div
                            key={task._id["$oid"]}
                            className="px-0 task-detail-row "
                          >
                            <Row className="my-1">
                              <Col className="d-flex align-items-center justify-content-between task-latest-row">
                                <span className=" px-2 span-background-deploy">
                                  <span className="span-color-md5">
                                    MD5&nbsp;
                                  </span>
                                  <Link
                                    to={`/image/${hash}`}
                                    className="link-color-hash"
                                  >
                                    {addressAbbreviation(hash, 6)}
                                  </Link>
                                </span>
                                <div>
                                  <Link to={`/user/${task.user_address}`}>
                                    {addressAbbreviation(task.user_address, 4)}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="d-flex align-items-center justify-content-between">
                                <span>
                                  <span>TaskID </span>
                                  <Link to={`/task/${task._id["$oid"]}`}>
                                    {task._id["$oid"]}
                                  </Link>
                                </span>
                                <span>
                                  <Time timestr={task.submit_time}></Time>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </CustomCard>
                  </Col> */}
                </Row>
                <Container className="px-0">
                  <h5 className="table-heading ">Prover List</h5>
                  {nodeStats.isLoading ? (
                    <>Loading Stats</>
                  ) : (
                    <>
                      <table className="main-table mt-2">
                        <thead>
                          <tr>
                            <th scope="col">Top Node Addresses</th>
                            <th scope="col">Successful Tasks</th>
                            <th scope="col">Failed Tasks</th>
                            <th scope="col">Total Tasks</th>
                            <th scope="col">Last Proof Time</th>
                            <th scope="col">Last Proof Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nodeStats.data?.data?.map((node) => (
                            <tr key={node.address}>
                              <td
                                scope="row"
                                className="td-address"
                                style={{ padding: "8px 0" }}
                              >
                                <a href={`/node?address=${node.address}`}>
                                  {node.address}
                                </a>
                              </td>
                              <td className="td-info">
                                {node.statistics?.successful_tasks || 0}
                              </td>
                              <td className="td-info">
                                {node.statistics?.failed_tasks || 0}
                              </td>
                              <td className="td-info">
                                {node.statistics?.total_tasks || 0}
                              </td>
                              <td className="td-info">
                                {node.statistics?.proof_timing_stats?.latest_time_taken_secs?.toFixed(
                                  4
                                ) || "N/A"}
                              </td>
                              <td className="td-info">
                                {node.statistics?.proof_timing_stats
                                  ?.latest_timestamp
                                  ? new Date(
                                      node.statistics.proof_timing_stats?.latest_timestamp
                                    ).toLocaleString()
                                  : "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="pagination-wrapper">
                        <a className="more-button me-4" href="/nodes">
                          More
                        </a>
                      </div>
                    </>
                  )}
                </Container>
                <Container className="table-container px-0">
                  <h5 className="table-heading ">Task History</h5>
                  {loadingTasks ? (
                    <>
                      <Row className="justify-content-center my-4">
                        <Spinner></Spinner>
                      </Row>
                      <Row className="justify-content-center my-4">
                        <div className="w-auto">Loading tasks...</div>
                      </Row>
                    </>
                  ) : (
                    <>
                      <table className="main-table mt-2">
                        <thead>
                          <tr>
                            <th scope="col">Task Id</th>
                            <th scope="col">Application Image</th>
                            <th scope="col">Published By</th>
                            <th scope="col">Type</th>
                            <th scope="col">Submit At</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasks.map((task, index) => {
                            return (
                              <tr key={task._id["$oid"]}>
                                <td
                                  scope="row"
                                  className="td-address text-left"
                                >
                                  <Link to={`task/${task._id["$oid"]}`}>
                                    {task._id["$oid"]}
                                  </Link>
                                </td>
                                <td className="min-width-lg">
                                  <Link to={`image/${task.md5}`}>
                                    {task.md5}
                                  </Link>
                                </td>
                                <td className="td-address min-width-md">
                                  <Link to={`/user/${task.user_address}`}>
                                    {addressAbbreviation(task.user_address, 4)}
                                  </Link>
                                </td>
                                <td className="td-info">{task.task_type}</td>

                                <td className="td-info min-width-md">
                                  <Time timestr={task.submit_time}></Time>
                                </td>
                                <td className="min-width-sm">
                                  <div
                                    className={`task-${getLineType(
                                      task.status
                                    )}-badge`}
                                  >
                                    {task.status}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* TODO: Remake pagination without bootstrap */}
                      <div className="pagination-wrapper">
                        <PaginationJump
                          totalQueryCount={totalQueryCount}
                          maxTasksPerPage={maxTasksPerPage}
                          setCurrentPage={handlePagination}
                        ></PaginationJump>
                        <Pagination className="justify-content-center">
                          <Pagination.First
                            onClick={() => handlePagination(1)}
                          />
                          <Pagination.Prev
                            onClick={() => handlePagination(currentPage - 1)}
                          />

                          <Pagination.Item active>
                            {currentPage} of{" "}
                            {Math.ceil(totalQueryCount / maxTasksPerPage)}
                          </Pagination.Item>

                          <Pagination.Next
                            onClick={() => handlePagination(currentPage + 1)}
                          />
                          <Pagination.Last
                            onClick={() =>
                              handlePagination(
                                Math.ceil(totalQueryCount / maxTasksPerPage)
                              )
                            }
                          />
                          {/* <PaginationAmount
                      pageSizeOptions={pageSizeOptions}
                      onPageSizeChange={handlePageSizeChange}
                    ></PaginationAmount> */}
                        </Pagination>
                      </div>
                    </>
                  )}
                  {loadingError &&
                    handleUnknownError(loadingErrorInfo).includes(
                      TOO_MANY_REQUESTS_ERR_CODE
                    ) && (
                      <Row className="justify-content-center my-4">
                        Could not fetch tasks, please try again later.
                      </Row>
                    )}
                </Container>

                <h5 className="table-heading ">
                  Auto Submit Proof Task History
                </h5>
                <Round1Table />
                <h5 className="table-heading">Round 1 Proof History</h5>
                <Round2Table />
                <h5 className="table-heading ">Round 2 Proof History</h5>
                <FinalTable />
              </Container>
            </>
          }
        ></Route>
        <Route
          path="/subscribe"
          element={<Subscription></Subscription>}
        ></Route>
        <Route path="/image/:md5" element={<ImageDetail></ImageDetail>}></Route>
        <Route
          path="/batchproof/round2/:taskId"
          element={<Round2BatchProofDetail />}
        ></Route>
        <Route
          path="/batchproof/final/:taskId"
          element={<FinalBatchProofDetail />}
        ></Route>
        <Route path="/task/:taskId" element={<TaskDetail></TaskDetail>}></Route>
        <Route path="/nodes" element={<NodeList></NodeList>}></Route>
        <Route path="/node" element={<NodeDetail></NodeDetail>}></Route>
        <Route
          path="/user/:userAddress"
          element={<UserDetail></UserDetail>}
        ></Route>
        <Route
          path="/table"
          element={<InstructionTable></InstructionTable>}
        ></Route>
        <Route path="/online_nodes_summary" element={<OnlineNodesSummaryPage></OnlineNodesSummaryPage>}></Route>
      </Routes>

      <Footer></Footer>
    </>
  );
}
